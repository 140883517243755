@keyframes CursorFlicker {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.list {
    position: relative;
    margin: .5rem 0 0 1rem;
}

ul.list {
    margin: 0;
    padding: 0;
}

ul.list li {
    list-style-type: none;
}

.list a {
    position: relative;
    color: var(--color-mint);
    line-height: 1.5rem;
    text-decoration: none;
    text-shadow:
            0 0 var(--glow-radius) var(--color-mint),
            0 0 calc(var(--glow-radius) + 5px) var(--color-mint);
    letter-spacing: -.08rem;
    border-bottom: 1px solid var(--color-mint);
    transition:
            color .1s ease-in-out,
            border-bottom-color .1s ease-in-out,
            text-shadow .15s ease-in-out;
}

.list.hoverable a:hover {
    color: var(--color-white);
    border-bottom-color: var(--color-white);
    text-shadow:
            0 0 var(--glow-radius) var(--color-white),
            0 0 calc(var(--glow-radius) + 10px) var(--color-white),
            0 0 calc(var(--glow-radius) + 10px) var(--color-white);
}

.list a:before {
    content: '\21B3';
    display: block;
    position: absolute;
    top: 0;
    left: -1rem;
}

.cursor {
    position: relative;
    left: 0.14rem;
    font-size: calc(1rem - 5px);
    letter-spacing: 3px;
    background-color: var(--color-mint);
    box-shadow:
            0 0 var(--glow-radius) var(--color-mint),
            0 0 var(--glow-radius) var(--color-mint);
    animation: none;
    opacity: 1;
}

.cursor-flickering .cursor {
    animation: 1s infinite CursorFlicker step-end;
}

.app-stopped .cursor {
    animation: none;
    opacity: 1;
}
