@font-face {
  font-family: 'OCROneWebRegular';
  src:
      url('/public/fonts/OCR.woff2') format('woff2'),
      url('/public/fonts/OCR.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  background-color: #000;
  font-size: 24px;
}

body {
  --color-green: #15a32d;
  --color-mint: #15a371;
  --color-indigo:  #616A8F;
  --color-dark-blue: rgba(21, 63, 113, .15);
  --color-white: #ecf1f1;
  --glow-radius: 20px;
  --border-glow-radius: 8px;
  overflow: hidden;
}

.App {
  position: absolute;
  z-index: 1;
}
