.Debug {
  position: absolute;
  padding: .2rem;
  z-index: 999;
  background: #000000;
  font-family: OCROneWebRegular, monospace;
  color: green;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
