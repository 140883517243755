@keyframes LoadingAnimation {
  0% {
    filter: blur(50px);
  }
  100% {
    filter: blur(0);
  }
}
.loading {
  animation: .5s LoadingAnimation ease-in;
}

.Screen {
  position: fixed;
  padding: 1rem 1.6rem 1.6rem;
  filter: brightness(150%);
  box-shadow: 0 0 var(--border-glow-radius) var(--color-mint),
    0 0 var(--border-glow-radius) var(--color-mint) inset;
  font-family: OCROneWebRegular, monospace;
  font-size: 1rem;
  background-color: #000000;
  border-width: 4px;
  border-style: double;
  border-color: var(--color-mint);
  color: var(--color-mint);
  z-index: 1;
}

.Screen::before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background:
      linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
      linear-gradient(90deg, rgba(255, 0, 0, 0.08), rgba(0, 255, 0, 0.04), rgba(0, 0, 255, 0.08));
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

h1 {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  margin: 0;
  color: var(--color-white);
  font-size: 1.8rem;
  text-shadow: 0 0 calc(var(--glow-radius)) var(--color-mint),0 0 calc(var(--glow-radius)) var(--color-mint);
  letter-spacing: -1px;
}

h2 {
  position: relative;
  margin: .3rem 0 0;
  font-size: 1.1rem;
  letter-spacing: -.08rem;
  text-shadow: 0 0 calc(var(--glow-radius)) var(--color-mint);
}

.list {
  position: relative;
  margin: .5rem 0 0 1rem;
}

.list a {
  position: relative;
  color: var(--color-mint);
  line-height: 1.5rem;
  text-decoration: none;
  text-shadow:
          0 0 var(--glow-radius) var(--color-mint),
          0 0 calc(var(--glow-radius) + 5px) var(--color-mint);
  letter-spacing: -.08rem;
  border-bottom: 1px solid var(--color-mint);
  transition:
          color .1s ease-in-out,
          border-bottom-color .1s ease-in-out,
          text-shadow .15s ease-in-out;
}

.list.hoverable a:hover {
  color: var(--color-white);
  border-bottom-color: var(--color-white);
  text-shadow:
          0 0 var(--glow-radius) var(--color-white),
          0 0 calc(var(--glow-radius) + 10px) var(--color-white),
          0 0 calc(var(--glow-radius) + 10px) var(--color-white);
}

.list a:before {
  content: '\21B3';
  display: block;
  position: absolute;
  top: 0;
  left: -1rem;
}